<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-tooltip text="Add Pair Device">
    <vs-button size="small" type="border" @click="popupActivo=true" icon-pack="feather" icon="icon-crosshair"></vs-button>
    </vx-tooltip>
      <vs-popup title="Add Pair Device" :active.sync="popupActivo">
        <vs-input
            :success="!errors.first('code') && code!=''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="(errors.first('code') ? true : false)"
            v-validate="'required|max:150'"
            name="code"
            data-vv-as="code"
            label="Pair Code"
            icon-no-border
            icon="icon icon-hash"
            icon-pack="feather"
            class="w-full"
            :danger-text="errors.first('code')"
            v-model="code" />

        <vs-button
        @click="pairDevices"
        color="primary"
        class="m-2"
        type="border">Pair</vs-button>

      </vs-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'pair-device',
  props: {
    clinicId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      popupActivo:false,
      code: ''
    };
  },
  methods: {
    ...mapActions('clinic', ['pairDevice']),
    async pairDevices() {
      let self = this;
      let isValid = await this.$validator.validate();
      this.pairDevice({clinicId: this.clinicId, code: this.code}).then(res => {
        self.$vs.notify({
          title:'Device Paired',
          text:'Device Paired Successfully.',
          color:'success'
        });
        this.popupActivo = false;
      }).catch(err =>{
          self.$vs.notify({
          title:'Failed',
          text: err.message,
          color:'danger'
        });
      });
    }
  },
};
</script>
