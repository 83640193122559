<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-tooltip text="List Pair Device">
      <vs-button size="small" @click="pairDevices" type="border" icon-pack="feather" icon="icon-list"></vs-button>
    </vx-tooltip>
      <vs-popup fullscreen title="Paired Devices" :active.sync="popupActivo">
        <vs-table
        ref="table"
        :sst="true"
        :data="devices"
        :noDataText="noDataText"
         >
        <template slot="thead">
          <vs-th sort-key="firstName">Clinic Name</vs-th>
          <vs-th sort-key="lastName">Device Id</vs-th>
          <vs-th sort-key="lastName">Device Type</vs-th>
          <vs-th sort-key="lastName">Device Name</vs-th>
          <vs-th size="25%" v-if="check_has_permission('removePairDevice')">Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="clinicName">{{ clinicName | capitalize }}</vs-td>
            <vs-td :data="data[indextr].deviceId">{{ data[indextr].deviceId | capitalize}}</vs-td>
            <vs-td :data="data[indextr].deviceType">{{ data[indextr].deviceType | capitalize}}</vs-td>
            <vs-td :data="data[indextr].deviceName">{{ data[indextr].deviceName | capitalize}}</vs-td>
            <vs-td v-if="check_has_permission('removePairDevice')" :data="data[indextr].deviceId">
              <vx-tooltip text="Unpair Device">
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-trash-2"
                  class="m-1"
                  @click="removeDevice(data[indextr].deviceId)"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      </vs-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'view-pair-device',
  props: {
    clinicId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      noDataText: 'Loading...',
      popupActivo:false,
      devices: [],
      clinicName: '',
    };
  },
  methods: {
    ...mapActions('clinic', ['fetchPairDevice', 'unPairDevice']),
    async pairDevices() {
      this.popupActivo = true;
      let self = this;
      
      this.fetchPairDevice({clinicId: this.clinicId}).then(res => {
        const data = res.data.data;
        self.clinicName = data.clinicName ? data.clinicName : data.firstName+' '+data.lastName;
        self.devices = res.data.data.pairedDevices;
        self.noDataText="No Paired Device Available"
      }).catch(err =>{
        self.noDataText="No Paired Device Available"
        console.log(err)
      });
    },
    removeDevice(deviceId) {
      this.unPairDevice({clinicId: this.clinicId, deviceId: deviceId}).then(res => {
        this.pairDevices();
      }).catch(err =>{
        console.log(err)
      });
    }
  },  
};
</script>
