<template>
  <vx-card style="padding-left: 10px" :key="rerenderKey">
    <div
      class="vx-row flex flex-row-reverse flex-no-wrap mb-2"
      v-if="
        check_has_permission('createClinicManagement') &&
        canCreate &&
        ($store.state.AppActiveUser.userType === 'superAdmin' ||
          $store.state.AppActiveUser.userType === 'admin')
      "
    >
      <vs-button
        class="ml-5"
        @click="createClinicHandler"
        icon-pack="feather"
        icon="icon-edit"
        >Create Community User</vs-button
      >
    </div>
    <div id="data-list-list-view" class="data-list-container">
      <div class="lg:hidden">
        <div class="mb-2 pr-2 flex flex-no-wrap">
          <div class="w-full pr-1">
            <vs-input
              icon="search"
              style="width: 100%"
              placeholder="Search"
              v-model="searchInputString"
            />
          </div>
          <div class="w-24 pl-1">
            <vs-select
              style="width: 100%"
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>
        <ul>
          <li :key="indextr" v-for="(tr, indextr) in users">
            <div class="rounded mb-2 p-2" style="border: 1px solid #e8e8e8">
              <div>
                <h4 class="mobile-text">
                  {{
                    users[indextr].clinicName
                      ? users[indextr].clinicName
                      : "N/A" | capitalize
                  }}
                </h4>
              </div>
              <div>
                <p class="mobile-text">
                  {{ users[indextr].postalAddress || "N/A" }}
                </p>
              </div>
              <div class="flex">
                <vx-tooltip
                  text="Edit Clinic Details"
                  v-if="check_has_permission('updateClinicManagement')"
                >
                  <vs-button
                    style="float: left"
                    v-if="canEdit"
                    type="border"
                    size="small"
                    @click="editDetailHandler(users[indextr]._id)"
                    icon-pack="feather"
                    icon="icon-edit"
                    class="m-1"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip
                  text="View Clinic Details"
                  v-if="check_has_permission('viewClinicManagement')"
                >
                  <vs-button
                    style="float: left"
                    v-if="canViewDetail"
                    type="border"
                    size="small"
                    @click="viewDetailHandler(users[indextr]._id)"
                    icon-pack="feather"
                    icon="icon-eye"
                    class="m-1"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip
                  v-if="
                    canViewClinicTreatmentHistory &&
                    check_has_permission('viewClinicTreatmentHistory')
                  "
                  text="Clinic Treatment History"
                >
                  <vs-button
                    style="float: left"
                    v-if="canViewClinicTreatmentHistory"
                    type="border"
                    size="small"
                    @click="viewTreatmentHistoryHandler(users[indextr]._id)"
                    icon-pack="feather"
                    icon="icon-activity"
                    class="m-1"
                  ></vs-button>
                </vx-tooltip>
                <!--<pair-device
                v-if="canPairDevice"
                style="float: left"
                :clinicId="data[indextr]._id"
                class="m-1"
              />
              <view-pair-device v-if="canViewPairDevice" class="m-1" :clinicId="data[indextr]._id" />-->

                <unassociate-clinic
                  style="float: left"
                  class="m-1"
                  v-if="canUnassociateClinic"
                  :clinicId="data[indextr]._id"
                  :clinicName="data[indextr].clinicName"
                  @completeTask="forceRerender"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <vs-table
        class="hidden lg:inline"
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="search"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between"
        >
          <div style="float: left">
            <vs-select
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName">First Name</vs-th>
          <vs-th sort-key="lastName">Last Name</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="hasApprovedByAdmin">Admin Approval</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].firstName">{{
              data[indextr].firstName | capitalize
            }}</vs-td>
            <vs-td :data="data[indextr].lastName">{{
              data[indextr].lastName | capitalize
            }}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>

            <vs-td :data="data[indextr].status">
              <p
                v-if="data[indextr].status === 'Pending_Approval'"
                class="text-sm"
              >
                Pending Approval
              </p>
              <p v-else class="text-sm">{{ data[indextr].status }}</p>
            </vs-td>

            <vs-td>{{ data[indextr].hasApprovedByAdmin }}</vs-td>

            <vs-td
              :data="data[indextr].createdAt"
              style="white-space: nowrap"
              >{{ data[indextr].createdAt | date_formatter }}</vs-td
            >
            <vs-td style="display: flex" :data="data[indextr]._id">
              <vx-tooltip
                text="Edit User Details"
                v-if="check_has_permission('updateClinicManagement')"
              >
                <vs-button
                  style="float: left"
                  v-if="canEdit"
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2 hidden lg:inline">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import pairDevice from "../others/addPairDevice";
import pairDeviceList from "../others/pairDeviceList";
import UnassociateClinic from "../others/UnassociateClinic";

export default {
  components: {
    pairDevice,
    "view-pair-device": pairDeviceList,
    "unassociate-clinic": UnassociateClinic,
  },
  props: {
    canUnassociateClinic: {
      type: Boolean,
      default: false,
    },
    canPairDevice: {
      type: Boolean,
      default: true,
    },
    canViewPairDevice: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canViewDetail: {
      type: Boolean,
      default: false,
    },
    viewRoute: {
      type: String,
      default: "FranchiseClinicDetail",
    },
    editRoute: {
      type: String,
      default: "SuperAdminCommunityEdit",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseClinicCreate",
    },
    canViewClinicTreatmentHistory: {
      type: Boolean,
      default: false,
    },
    clinicTreatmentHistoryRoute: {
      type: String,
      default: "SuperAdminClinicTreatmentHistory",
    },
  },
  data() {
    return {
      noDataText: "Loading..",
      rerenderKey: 0,
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        userType: "basic",
        activeClinicId: "",
      },
      serverResponded: false,
      users: [],
      date: new Date(),
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      awaitingSearch: null,
    };
  },
  methods: {
    // ...mapActions("clinic", ["fetchCommunityMembers"]),
    ...mapActions("communityMemebers", ["fetchCommunityMembers"]),
    search(searching) {
      this.searchInputString = searching;
    },
    async getClinicsList() {
      let self = this;
      self.$vs.loading();
      await this.fetchCommunityMembers(self.dataTableParams).then((res) => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.serverResponded = true;
        self.noDataText = "No Clinics Available";
        self.$vs.loading.close();
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getClinicsList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getClinicsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getClinicsList();
    },
    editDetailHandler(id) {
      this.$router.push({
        name: "SuperAdminCommunityEdit",
        params: { id: id },
      });
    },
    createClinicHandler() {
      this.$router.push({ name: "SuperAdminCommunityCreate" });
    },
    onboardClinicHandler() {
      this.$router.push({ name: "SuperAdminClinicOnboard" });
    },
    viewDetailHandler(id) {
      this.$router.push({
        name: this.viewRoute,
        params: { clinicId: id },
      });
    },
    viewTreatmentHistoryHandler(id) {
      this.$router.push({
        name: this.clinicTreatmentHistoryRoute,
        params: { clinicId: id },
      });
    },
    forceRerender() {
      this.rerenderKey += 1;
    },
  },
  watch: {
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getClinicsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getClinicsList();
      }
    },
    rerenderKey: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getClinicsList();
      }
    },
    "$store.state.AppActiveClinicId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.activeClinicId = newVal;
        this.getClinicsList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  async created() {
    this.dataTableParams.activeClinicId = await this.$store.state
      .AppActiveClinicId;
    this.getClinicsList();
  },
};
</script>

<style scoped>
ul li {
  padding-right: 5px;
}
.mobile-text {
  color: #164d3d;
}
</style>
