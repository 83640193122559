<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-tooltip text="Unassociate This Clinic">
      <vs-button
        @click="activePrompt=true"
        size="small"
        type="border"
        icon-pack="feather"
        icon="icon-x-circle"
      ></vs-button>
    </vx-tooltip>
    <vs-prompt
      @accept="acceptAlert"
      :active.sync="activePrompt"
      title="Unassociate Clinic"
      accept-text="Yes"
    >
      <div class="con-exemple-prompt">Are you sure to unassociate with {{clinicName}} ?</div>
    </vs-prompt>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "unassociate-clinic",
  props: {
    clinicId: {
      type: String,
      default: null
    },
    clinicName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      activePrompt: false
    };
  },
  methods: {
    ...mapActions("general", ["unassociateClinic"]),
    async acceptAlert() {
      this.$vs.loading();
      let self = this;
      this.unassociateClinic({ clinicId: this.clinicId })
        .then(res => {
          self.$vs.notify({
            title: "Success",
            text:
              "You have successfully unassociated with the selected clinic.",
            color: "success"
          });
          this.activePrompt = false;
          this.$emit("completeTask");
          this.$vs.loading.close();
        })
        .catch(err => {
          self.$vs.notify({
            title: "Error",
            text: err.data.message,
            color: "danger"
          });
          this.$vs.loading.close();
        });
    }
  }
};
</script>

<style scoped>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
</style>
