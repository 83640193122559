<template>
  <div>
    <list
      :canEdit="true"
      editRoute="SuperAdminClinicEdit"
      :canCreate="true"
      createRoute="SuperAdminClinicCreate"
      :canViewDetail="true"
      viewRoute="SuperAdminClinicDetail"
      :canViewClinicTreatmentHistory="true"
      clinicTreatmentHistoryRoute="SuperAdminClinicTreatmentHistory"
    />
  </div>
</template>

<script>
import list from "../../components/community-portal/list.vue";

export default {
  components: {
    list,
  },
  data: () => ({}),
};
</script>
